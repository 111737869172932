enum SchemaConstants {
    subdomain = "subdomain",
    address = "address",
    name = "name",
    phone = "phone",
    email = "email",
    marketPartnerIDCode = "marketPartnerIDCode",
    marketPartnerIDEncoding = "marketPartnerIDEncoding",
    onCallServiceEmail = "onCallServiceEmail",
    onCallServicePhone = "onCallServicePhone",
    callRetryDelays = "callRetryDelays",
    tenantName = "tenantName",
    mainContact = "mainContact",
    exPostRecipients = "exPostRecipients"
}

export default SchemaConstants;