import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import Tenant from "../../../api/fixed/Tenant"
import ICertificate, {CertificateType} from "../../../api/fixed/Certificate"
import {
    AddTenant,
    AddTenantUser,
    DeleteTenantUser,
    editTenantActivation,
    editTenantDetails,
    EditTenantUser,
    fetchCertificate,
    fetchTenantByID,
    fetchTenantListByGridDataState,
    ImportCertificate
} from "./Thunks"


export interface TenantsState {
    tenants: Tenant[] | undefined,
    currentTenant: Tenant | null | undefined,
    certificates: ICertificate
}

const initialState: TenantsState = {
    tenants: undefined,
    currentTenant: undefined,
    certificates: {
        encryption: "none",
        decryption: "none",
        auth: "none"
    }
};

export const TenantsSlice = createSlice({
    name: "Tenants",
    initialState,
    reducers: {
        setExPostRecipients: (state, action: PayloadAction<string[] | undefined>) => {
            if (state.currentTenant)
                state.currentTenant.exPostRecipients = action.payload;
        },
        updateNewExPostRecipient: (state, action:PayloadAction<string>) => {
            if (state.currentTenant && state.currentTenant.exPostRecipients)
                state.currentTenant.exPostRecipients[0] = action.payload;
        },
        setCallRetryDelays: (state, action: PayloadAction<number[] | undefined>) => {
            if (state.currentTenant)
                state.currentTenant.callRetryDelays = action.payload;
        },
        updateNewCallRetryDelay: (state, action: PayloadAction<number>) => {
            if (state.currentTenant && state.currentTenant.callRetryDelays) {
                state.currentTenant.callRetryDelays[0] = action.payload;
            }
        },
        swapCallRetries: (state, action: PayloadAction<{ startIndex: number, endIndex: number }>) => {
            const { startIndex, endIndex } = action.payload;
            if (state.currentTenant && state.currentTenant.callRetryDelays) {
                const startItem = state.currentTenant.callRetryDelays[startIndex];
                state.currentTenant.callRetryDelays[startIndex] = state.currentTenant.callRetryDelays[endIndex]
                state.currentTenant.callRetryDelays[endIndex] = startItem;
            }
        },
        setMarketPartnerIDCode: (state, action: PayloadAction<string>) => {
            if (state.currentTenant) {
                state.currentTenant.marketPartnerIDCode = action.payload;
            }
        },
        setOnCallServiceEmail: (state, action: PayloadAction<string>) => {
            if (state.currentTenant) {
                state.currentTenant.onCallServiceEmail = action.payload;
            }
        },
        setOnCallServicePhone: (state, action: PayloadAction<string>) => {
            if (state.currentTenant) {
                state.currentTenant.onCallServicePhone = action.payload;
            }
        },
        setMarketPartnerIDEncoding: (state, action: PayloadAction<string>) => {
            if (state.currentTenant) {
                state.currentTenant.marketPartnerIDEncoding = action.payload;
            }
        },
        setMainContactName: (state, action: PayloadAction<string>) => {
            if (state.currentTenant) {
                state.currentTenant.mainContact = {...state.currentTenant.mainContact, name: action.payload};
            }
        },
        setName: (state, action: PayloadAction<string>) => {
            if (state.currentTenant) {
                state.currentTenant.name = action.payload;
            }
        },
        setPhone: (state, action: PayloadAction<string>) => {
            if (state.currentTenant) {
                state.currentTenant.mainContact = {...state.currentTenant.mainContact, phone: action.payload};
            }
        },
        setMail: (state, action: PayloadAction<string>) => {
            if (state.currentTenant) {
                state.currentTenant.mainContact = {...state.currentTenant.mainContact, email: action.payload};
            }
        },
        setAddress: (state, action: PayloadAction<string>) => {
            if (state.currentTenant) {
                state.currentTenant.address = action.payload;
            }
        },
        setSubdomain: (state, action: PayloadAction<string>) => {
            if (state.currentTenant) {
                state.currentTenant.subdomain = action.payload;
            }
        },
        setCurrentTenant: (state, action: PayloadAction<Tenant | null | undefined>) => {
            state.currentTenant = action.payload
        },
        setTenants: (state, action: PayloadAction<Tenant[] | undefined>) => {
            state.tenants = action.payload
        },
        setCertificates: (state, action: PayloadAction<ICertificate>) => {
            state.certificates = action.payload
        },
        setUseAVAT: (state, action: PayloadAction<boolean>) => {
            if (state.currentTenant)
                state.currentTenant.useAvatForActivations = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTenantListByGridDataState.fulfilled, (state, action) => {
                state.tenants = action.payload
            })
            .addCase(fetchTenantByID.fulfilled, (state, action) => {
                state.currentTenant = action.payload
            })
            .addCase(editTenantActivation.fulfilled, (state, action) => {
                if (action.payload !== "error" && state.tenants !== undefined) {
                    if (state.currentTenant) {
                        state.currentTenant.revision = action.payload.revision
                        state.currentTenant.isActive = action.payload.activeState
                    }
                    const newTenants = state.tenants.find((item) => item.inventoryItemId === action.payload.inventoryItemId)
                    if (newTenants) {
                        newTenants.revision = action.payload.revision;
                        newTenants.isActive = action.payload.activeState
                    }

                }
            })
            .addCase(editTenantDetails.fulfilled, (state, action) => {
                if (action.payload !== "error" && state.tenants !== undefined) {
                    if (state.currentTenant) {
                        state.currentTenant.revision = action.payload.revision
                        state.currentTenant.mainContact = action.payload.mainContact
                        state.currentTenant.name = action.payload.name
                        state.currentTenant.address = action.payload.address
                        state.currentTenant.onCallServiceEmail = action.payload.onCallServiceEmail
                        state.currentTenant.onCallServicePhone = action.payload.onCallServicePhone
                    }
                    const newTenants = state.tenants.find((item) => item.inventoryItemId === action.payload.inventoryItemId)
                    if (newTenants) {
                        newTenants.revision = action.payload.revision
                        newTenants.mainContact = action.payload.mainContact
                        newTenants.name = action.payload.name
                        newTenants.address = action.payload.address
                        newTenants.onCallServiceEmail = action.payload.onCallServiceEmail
                        newTenants.onCallServicePhone = action.payload.onCallServicePhone
                    }
                }
            })
            .addCase(AddTenant.fulfilled, (state, action) => {
                if (action.payload !== "error") {
                    if (state.tenants !== undefined) {
                        state.tenants.push(action.payload)
                    } else {
                        state.tenants = [action.payload]
                    }
                    state.currentTenant = action.payload
                }
            })
            .addCase(AddTenantUser.fulfilled, (state, action) => {
                if (state.tenants !== undefined) {
                    state.currentTenant?.users?.push(action.payload.user);
                    if (state.currentTenant)
                        state.currentTenant.revision = action.payload.tenant.revision;

                    const newTenants = state.tenants.find((item) => item.inventoryItemId === action.payload.tenant.inventoryItemId)
                    if (newTenants) {
                        newTenants.users?.push(action.payload.user);
                        newTenants.revision = action.payload.tenant.revision
                    }
                }
            })
            .addCase(EditTenantUser.fulfilled, (state, action) => {
                if (state.tenants !== undefined) {
                    const newUsers = state.currentTenant?.users?.map((item) =>
                        item.inventoryItemId === action.payload.inventoryItemId ? action.payload : item)

                    if (newUsers !== undefined) {
                        if (state.currentTenant) {
                            state.currentTenant.users = newUsers
                        }

                        const newTenants = state.tenants.find((item) => item.inventoryItemId === state.currentTenant?.inventoryItemId)
                        if (newTenants)
                            newTenants.users = newUsers
                    }
                }
            })
            .addCase(DeleteTenantUser.fulfilled, (state, action) => {
                if (action.payload !== "error" && state.tenants) {
                    const newUsers = state.currentTenant?.users?.filter((item) => item.inventoryItemId !== action.payload)
                    if (state.currentTenant && newUsers) {
                        state.currentTenant.users = newUsers
                    }

                    const newTenants = state.tenants.find((item) => item.inventoryItemId === state.currentTenant?.inventoryItemId)
                    if (newTenants && newUsers)
                        newTenants.users = newUsers
                }
            })
            .addCase(ImportCertificate.fulfilled, (state, action) => {
                if (state.currentTenant)
                    state.currentTenant.revision = action.payload.data.tenant.revision

                if (action.payload.type === "smime-decryption") {
                    state.certificates.decryption = action.payload.data.certificateUploadedDate
                }
                else if (action.payload.type === "smime-encryption")
                    state.certificates.encryption = action.payload.data.certificateUploadedDate
                else
                    state.certificates.auth = action.payload.data.certificateUploadedDate

            })
            .addCase(fetchCertificate.fulfilled, (state, action) => {
                if (action.payload.type === CertificateType.decryption) {
                    state.certificates.decryption = action.payload.date
                }
                else if (action.payload.type === CertificateType.encryption)
                    state.certificates.encryption = action.payload.date
                else
                    state.certificates.auth = action.payload.date

            })
    },
})


export const { setCurrentTenant, setUseAVAT, setExPostRecipients, updateNewExPostRecipient, swapCallRetries, setCallRetryDelays, updateNewCallRetryDelay, setOnCallServicePhone, setOnCallServiceEmail, setCertificates, setTenants, setSubdomain, setAddress, setName, setPhone, setMail, setMarketPartnerIDCode, setMarketPartnerIDEncoding, setMainContactName } = TenantsSlice.actions;

export default TenantsSlice.reducer
