import React, {ReactElement, useCallback} from "react";
import {Field, GeneralSimpleElementTable, isFieldValid, isSchemaValid, PageStatus} from "ndr-designsystem";
import {useTranslation} from "react-i18next";
import SchemaConstants from "../../../utils/schemaConstants";
import {
    setAddress,
    setExPostRecipients,
    setMarketPartnerIDCode,
    setMarketPartnerIDEncoding,
    setName,
    setSubdomain,
    setUseAVAT,
    updateNewExPostRecipient
} from "../store/TenantsSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {EmailSchema, GeneralTabSchema} from "../../../validations/TenantDetailsValidation";

const GeneralTab = ({pageStatus}:{pageStatus: PageStatus}): ReactElement => {
    const dispatch = useAppDispatch();
    const isEditing = pageStatus === PageStatus.EDIT
    const isAdding = pageStatus === PageStatus.ADD
    const { currentTenant: tenant} = useAppSelector(state => state.Tenants)
    const {t} = useTranslation("tabels")
    const generalTabSchema = GeneralTabSchema(isAdding);

    const getExPostRecipientActions = useCallback(() => ({
        setElements: (elements: string[] | undefined) => {
            dispatch(setExPostRecipients(elements))
        },
        updateNewElement: (element: string) => {
            dispatch(updateNewExPostRecipient(element));
        },
    }), [dispatch])


    return <>
        {isAdding &&
            <Field
                onValueChange={() => {}}
                isEditing={false}
                inputType="text"
                defaultValue={tenant?.inventoryItemId}
                title={t("tenantDetails.ID")}
            />
        }
        <Field
            inputType="text"
            isEditing={isAdding}
            valueCheckFunction={subdomain => isFieldValid(generalTabSchema, [SchemaConstants.subdomain], { subdomain })}
            onValueChange={(subdomain) => dispatch(setSubdomain(subdomain))}
            defaultValue={tenant?.subdomain}
            title={t("tenantDetails.subdomain")}
        />
        <Field
            inputType="text"
            isEditing={isEditing || isAdding}
            valueCheckFunction={address => isFieldValid(generalTabSchema, [SchemaConstants.address], { address })}
            onValueChange={(address) => dispatch(setAddress(address))}
            defaultValue={tenant?.address}
            title={t("tenantDetails.address")}
        />
        <Field
            inputType="text"
            isEditing={isEditing || isAdding}
            valueCheckFunction={name => isFieldValid(generalTabSchema, [SchemaConstants.name], { name })}
            onValueChange={(name) => dispatch(setName(name))}
            defaultValue={tenant?.name}
            title={t("tenantDetails.name")}
        />
        <Field
            inputType="text"
            isEditing={isAdding}
            valueCheckFunction={marketPartnerIDCode => isFieldValid(generalTabSchema, [SchemaConstants.marketPartnerIDCode], { marketPartnerIDCode })}
            onValueChange={(marketPartnerIDCode) => dispatch(setMarketPartnerIDCode(marketPartnerIDCode))}
            defaultValue={tenant?.marketPartnerIDCode}
            title={t("tenantDetails.marketPartnerIDCode")}
        />
        <Field
            inputType="text"
            isEditing={isAdding}
            valueCheckFunction={marketPartnerIDEncoding => isFieldValid(generalTabSchema, [SchemaConstants.marketPartnerIDEncoding], { marketPartnerIDEncoding })}
            onValueChange={(marketPartnerIDEncoding) => dispatch(setMarketPartnerIDEncoding(marketPartnerIDEncoding))}
            defaultValue={tenant?.marketPartnerIDEncoding}
            title={t("tenantDetails.marketPartnerIDEncoding")}
        />
        <Field
            isEditing={isEditing || isAdding}
            onValueChange={(useAvat) => dispatch(setUseAVAT(useAvat))}
            title={t("tenantDetails.UseAVAT")}
            inputType="boolean"
            defaultValue={tenant?.useAvatForActivations}
        />
        <GeneralSimpleElementTable
            canReorder={false}
            defaultOpen
            noRecordsText={t("common:no_records_text")}
            onMoreThanOneError={() => {
                alert(t("one_by_one"))
            }}
            addButtonText={t("common:add_new")}
            elements={tenant?.exPostRecipients}
            valueCheckFunction={val => isSchemaValid(EmailSchema(), val)}
            defaultElement=""
            gridCheckFunction={exPostRecipients => isFieldValid(generalTabSchema, [SchemaConstants.exPostRecipients], { exPostRecipients })}
            pageStatus={pageStatus}
            actions={getExPostRecipientActions()}
            type="freeText"
            title={t('tenantDetails.exPostRecipients')}
        />

        {!isAdding &&
            <>
                <Field
                    onValueChange={() => {}}
                    isEditing={false}
                    inputType="text"
                    defaultValue={tenant?.controllableResourcesCount}
                    title={t("tenantDetails.NumberOfControllableResources")}
                />
                <Field
                    onValueChange={() => {}}
                    isEditing={false}
                    inputType="text"
                    defaultValue={tenant?.activationsCount}
                    title={t("tenantDetails.NumberOfActivations")}
                />
            </>
        }
    </>
}

export default GeneralTab